<template>
  <div id="thermal">
    <div
      class="page container"
      size="A4"
      v-for="page in pages"
      :key="'page' + page"
    >
      <v-row class="pt-2 align-center">
        <v-col class="d-flex flex-row align-center justify-center">
          <v-avatar size="50">
            <v-img
              src="https://links.casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>

          <b style="font-size: 12px">Rede Casas das Tintas</b>
        </v-col>
      </v-row>

      <hr class="my-4" />

      <v-row class="text-center my-2">
        <v-col><h2>Romaneio de Transferência</h2> </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex flex-column">
          <b> Código {{ stock_transfer.code }}</b>
          <b>Descrição: {{ stock_transfer.description }} </b>

          <b>
            Origem: {{ stock_transfer.company_origin.code }} -
            {{ stock_transfer.company_origin.name }}
          </b>

          <b>
            Destino: {{ stock_transfer.company_destination.code }} -
            {{ stock_transfer.company_destination.name }}
          </b>

          <b>
            Criado por: {{ stock_transfer.created_by.name }} em
            {{ $format.dateTimeBr(stock_transfer.issued_at) }}
          </b>

          <b v-if="stock_transfer.canceled_by">
            Recebido por: {{ stock_transfer.created_by.name }}
            {{ $format.dateTimeBr(stock_transfer.received_at) }}
          </b>

          <b v-if="stock_transfer.received_by">
            Cancelado por: {{ stock_transfer.created_by.name }}
            {{ $format.dateTimeBr(stock_transfer.canceled_at) }}
          </b>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col>
          <span>

          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-space-between">
          <span class="d-flex flex-column text-center">

          </span>

          <span class="d-flex flex-column text-center">
 
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex flex-column">
          <span>
            <b>Cod. </b>
          </span>

    
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <v-data-table
            :page="page"
            :headers="headers"
            :items="getItems(page)"
            :items-per-page="itensCount"
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.product`]="{ item }">
              <span v-if="item.product">
                {{ item.product.code }} - {{ item.product.name }}
              </span>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <span v-if="item.product">
                {{ $format.decimal(item.quantity) }}
                {{ item.product_variant.unity.symbol }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <template v-if="page == pages">
        <v-row style="word-break: break-word;padding-bottom: auto; padding-top: 10px">
          <v-col> Observação: {{ stock_transfer.observations }} </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stock_transfer: {},
  },
  data() {
    return {
      headers: [
        { text: "Produto", value: "product" },

        {
          text: "Quantidade	",
          value: "quantity",
          align: "center",
          width: "10%",
        },
      ],
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.itensCount / this.itensCount);
    },

    transfer_products() {
      return this.stock_transfer.stock_transfer_products;
    },
    itensCount() {
      if (this.transfer_products) {
        return this.transfer_products.length;
      }
      return 1;
    },
  },
  methods: {
    hasItemOnIndex(page, item) {
      return this.getIndex(page, item) < this.itensCount;
    },

    isFirstPage(page) {
      return page == 1;
    },

    getItems(page) {
      let items = this.transfer_products.slice(
        (page - 1) * this.itensCount,
        page * this.itensCount
      );
      if (items.length < this.itensCount) {
        for (let i = items.length; i < this.itensCount; i++) {
          items.push({});
        }
      }
      return items;
    },
  },
};
</script>

<style  lang="scss">
@import "@/assets/style/prints/ThermalPrinting.scss";
</style>
